import styled from "styled-components"

const MarginContainer = styled.div`
  position: relative;
  margin: auto;
  max-width: 1600px;
  width: 100%;
  height: inherit;
`

export default MarginContainer
