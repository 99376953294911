const theme = {
  spaces: ["15px", "20px", "25px", "30px", "35px"],
  fonts: {
    andellia: "Andellia Davilton",
    proxima: "Proxima Nova, sans-serif",
  },
  fontSize: {
    sm: "0.8rem",
    rg: "1rem",
    md: "1.25rem",
    lg: "1.563rem",
    xl: "1.953rem",
    xxl: "2.441rem",
    xxxl: "3.052rem",
  },
  colors: {
    blue: "#1F4E79",
    lightBlue: "#58C5D4",
    darkBlue: "#0F263C",
    violet: "#2D2F6E",
    black: "#222222",
  },
  breakpoints: ["576px", "768px", "992px", "1200px"],
}

export default theme
