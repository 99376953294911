// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-class-2021-jsx": () => import("./../../../src/pages/class2021.jsx" /* webpackChunkName: "component---src-pages-class-2021-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-live-jsx": () => import("./../../../src/pages/live.jsx" /* webpackChunkName: "component---src-pages-live-jsx" */),
  "component---src-pages-programs-jsx": () => import("./../../../src/pages/programs.jsx" /* webpackChunkName: "component---src-pages-programs-jsx" */),
  "component---src-pages-through-the-years-come-jsx": () => import("./../../../src/pages/through-the-years/come.jsx" /* webpackChunkName: "component---src-pages-through-the-years-come-jsx" */),
  "component---src-pages-through-the-years-funked-me-up-jsx": () => import("./../../../src/pages/through-the-years/funked-me-up.jsx" /* webpackChunkName: "component---src-pages-through-the-years-funked-me-up-jsx" */),
  "component---src-pages-through-the-years-index-jsx": () => import("./../../../src/pages/through-the-years/index.jsx" /* webpackChunkName: "component---src-pages-through-the-years-index-jsx" */),
  "component---src-pages-through-the-years-lu-3-jsx": () => import("./../../../src/pages/through-the-years/lu3.jsx" /* webpackChunkName: "component---src-pages-through-the-years-lu-3-jsx" */),
  "component---src-pages-through-the-years-lu-4-jsx": () => import("./../../../src/pages/through-the-years/lu4.jsx" /* webpackChunkName: "component---src-pages-through-the-years-lu-4-jsx" */),
  "component---src-pages-through-the-years-lu-5-jsx": () => import("./../../../src/pages/through-the-years/lu5.jsx" /* webpackChunkName: "component---src-pages-through-the-years-lu-5-jsx" */),
  "component---src-pages-through-the-years-lu-6-jsx": () => import("./../../../src/pages/through-the-years/lu6.jsx" /* webpackChunkName: "component---src-pages-through-the-years-lu-6-jsx" */),
  "component---src-pages-through-the-years-lu-7-jsx": () => import("./../../../src/pages/through-the-years/lu7.jsx" /* webpackChunkName: "component---src-pages-through-the-years-lu-7-jsx" */),
  "component---src-pages-through-the-years-mediscene-jsx": () => import("./../../../src/pages/through-the-years/mediscene.jsx" /* webpackChunkName: "component---src-pages-through-the-years-mediscene-jsx" */),
  "component---src-pages-through-the-years-tao-rin-pala-jsx": () => import("./../../../src/pages/through-the-years/tao-rin-pala.jsx" /* webpackChunkName: "component---src-pages-through-the-years-tao-rin-pala-jsx" */),
  "component---src-templates-speaker-template-jsx": () => import("./../../../src/templates/SpeakerTemplate.jsx" /* webpackChunkName: "component---src-templates-speaker-template-jsx" */)
}

