import styled from "styled-components"

const FoldContainer = styled.div`
  ${({ theme }) => `
    height: fit-content;
    padding-left: ${theme.spaces[0]};
    padding-right: ${theme.spaces[0]};
    width: calc(100% - ${theme.spaces[0]} * 2);
  `}
`

export default FoldContainer
