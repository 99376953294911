import React from "react"
import styled from "styled-components"
import FoldContainer from "./FoldContainer"

const Container = styled(FoldContainer)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.blue};
    color: white;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.sm};
    padding: ${theme.spaces[2]} ${theme.spaces[0]} ${theme.spaces[2]} ${theme.spaces[0]};
    text-align: center;
  `}
`

const BoldText = styled.span`
  font-family: inherit;
  font-weight: bold;
`

const Footer = () => (
  <Container>
    <BoldText>UPCM Commencement Exercises 2021</BoldText>
    <span>
      Prepared by the UPCM Class of 2021 Graduation Rites Committee with the
      help of Gabrielle Flores and Franrey Saycon
    </span>
  </Container>
)

export default Footer
