import React, { useRef } from "react"
import { motion } from "framer-motion"
import useClickOutside from "../../hooks/useClickOutside"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Match } from "@reach/router"
import { createPortal } from "react-dom"

const Container = styled(motion.div)`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 200;
  top: 0;
  background-color: rgba(20, 20, 20, 0.8);
`

const NavigationContainer = styled(motion.div)`
  ${({ theme }) => `
    height: 100%;
    width: 250px;
    padding-top: ${theme.spaces[3]};
    z-index: 11;
    background-color: ${theme.colors.blue};
  `}
`

const NavItem = styled.div`
  ${({ theme, selected }) => `
    padding: 20px 0px 20px 20px;
    width: calc(100% - 20px);
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.lg};
    color: white;
    background-color: ${selected ? theme.colors.darkBlue : "transparent"};

    &:hover {
      background-color: ${theme.colors.darkBlue};
    }
  `}
`

const Sidebar = ({ onClose }) => {
  const sidebarRef = useRef()
  useClickOutside(sidebarRef, onClose)

  const navigateThenClose = path => {
    navigate(path)
    onClose()
  }

  return createPortal(
    <Container
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      layout={true}
    >
      <NavigationContainer
        animate={{ x: "0" }}
        initial={{ x: "-50%" }}
        exit={{ x: "-50%" }}
        transition={{
          ease: "easeIn",
          duration: 0.3,
        }}
        ref={sidebarRef}
      >
        <Match path="/">
          {({ match }) => (
            <NavItem selected={match} onClick={() => navigateThenClose("/")}>
              Home
            </NavItem>
          )}
        </Match>
        <Match path="/through-the-years/*">
          {({ match }) => (
            <NavItem
              selected={match}
              onClick={() => navigateThenClose("/through-the-years")}
            >
              Through the Years
            </NavItem>
          )}
        </Match>
        <Match path="/programs/*">
          {({ match }) => (
            <NavItem
              selected={match}
              onClick={() => navigateThenClose("/programs")}
            >
              Programs
            </NavItem>
          )}
        </Match>
        <Match path="/class2021/*">
          {({ match }) => (
            <NavItem
              selected={match}
              onClick={() => navigateThenClose("/class2021")}
            >
              Class 2021
            </NavItem>
          )}
        </Match>
      </NavigationContainer>
    </Container>,
    document.body
  )
}

export default Sidebar
