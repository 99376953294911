import React from "react"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import theme from "./theme"
import "./global.css"
import Footer from "./components/Footer"
import Header from "./components/Header"
import { ModalProvider } from "styled-react-modal"
import SocialMediaPreview from "./images/social-media-preview.jpg"

const RootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <ModalProvider>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title="UPCM 2021: Salimbay"
        link={[
          {
            rel: "canonical",
            href: "https://upcm2021.com",
          },
        ]}
        meta={[
          {
            name: "description",
            content:
              "Join the UPCM Class 2021 as they celebrate SALIMBAY, the 112th Commencement Exercises of the University of the Philippines College of Medicine.",
          },
          {
            property: "og:title",
            content: "UPCM 2021: Salimbay",
          },
          {
            property: "og:description",
            content:
              "Join the UPCM Class 2021 as they celebrate SALIMBAY, the 112th Commencement Exercises of the University of the Philippines College of Medicine.",
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:image",
            content: SocialMediaPreview,
          },
          {
            property: "og:image:width",
            content: 1200,
          },
          {
            property: "og:image:height",
            content: 628,
          },
          {
            property: "og:image:alt",
            content:
              "Salimbay pubmat of UPCM Class 2021 Commencement Exercises",
          },
          {
            name: "twitter:title",
            content: "UPCM 2021: Salimbay",
          },
          {
            name: "twitter:description",
            content:
              "Join the UPCM Class 2021 as they celebrate SALIMBAY, the 112th Commencement Exercises of the University of the Philippines College of Medicine.",
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:image",
            content: SocialMediaPreview,
          },
        ]}
      />
      <Header />
      {element}
      <Footer />
    </ModalProvider>
  </ThemeProvider>
)

export default RootElement
