/* eslint-disable */
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import UpManilaLogo from "../../images/up-manila-logo.png"
import PghLogo from "../../images/pgh-logo.png"
import UpcmManilaLogo from "../../images/upcm-manila-logo.png"
import UpcmClassLogo from "../../images/upcm-class-2021-logo.png"
import MarginContainer from "../MarginContainer"
import { Match } from "@reach/router"
import { Menu } from "react-feather"
import Sidebar from "./Sidebar"
import { Link, navigate } from "gatsby"
import { motion } from "framer-motion"
import { useLocation } from "@reach/router"


const Container = styled(motion.div)`
  ${({ theme, scrolled, transparent }) => `
    position: fixed;
    top: 0px;
    z-index: 100;
    display: block;
    width: calc(100% - 2 * ${theme.spaces[1]});
    padding: 0px ${theme.spaces[1]};
    transition: background-color 0.3s ease;
    background-color: ${(scrolled || !transparent) ? theme.colors.blue : "transparent"};
  `}
`

const HeaderContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    z-index: 1;
    padding: ${theme.spaces[0]} 0;
  `}
`

const LogoContainer = styled.div`
  ${({ theme }) => `
    display: flex;

    & > img {
      margin-right: ${theme.spaces[0]};
    }

    & > img:last-child {
      margin-right: 0;
    }
  `}
`

const BoxImage = styled.img`
  width: 3rem;
  height: 3rem;
`

const WiderBoxImage = styled(BoxImage)`
  width: 3.75rem;
`

const NavigationContainer = styled.div`
  ${({ theme }) => `
    display: none;

    & > a {
      margin-right: ${theme.spaces[1]};
    }

    & > a:last-child {
      margin-right: 0;
    }

    @media(min-width: ${theme.breakpoints[1]}) {
      display: flex;
    }
  `}
`

const NavItem = styled.div`
  ${({ theme, selected }) => `
    border-bottom: 3px solid;
    border-color: ${selected ? theme.colors.lightBlue : "transparent"};
    color: white;
    display: inline-block;
    font-family: ${theme.fonts.proxima};
    font-size: ${theme.fontSize.sm};
    text-transform: uppercase;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: ${theme.colors.lightBlue};
      cursor: pointer;
    }
  `}
`

const MenuContainer = styled.div`
  ${({ theme }) => `
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    @media(min-width: ${theme.breakpoints[1]}) {
      display: none;
    }
  `}
`

const ResetLink = styled(Link)`
  text-decoration: none;
`

const Announcement = styled.div`
  ${({ theme }) => `
    display: flex;
    padding: 10px;
    flex: 1;
    margin: 0px -20px;
    background-color: ${theme.colors.darkBlue};
    color: white;
    align-items: center;
    justify-content: center;
    font-family: ${theme.fonts.proxima};
    font-size: 0.7rem;
  `}
`

const AnnouncementButton = styled.button`
  ${({ theme }) => `
    background-color: ${theme.colors.violet};
    color: white;
    display: inline-block;
    font-family: ${theme.fonts.proxima};
    font-size: 0.6rem;
    padding: 5px 20px;
    outline: 0;
    text-decoration: none;
    border: 0;
    box-shadow: none;
    border-radius: 20px;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  `}
`

const Header = () => {
  const [scrolled, setScrolled] = useState(false)
  const [sideBarOpen, setSidebarOpen] = useState(false)

  const location = useLocation()
  const isLivePage = location.pathname.includes("/live")

  useEffect(() => {
    if(window.pageYOffset > 10){
      setScrolled(true)
    }
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        if (window.pageYOffset > 10) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }
    }
  }, [])

  return (
    <>
      <Container
        scrolled={scrolled}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: -10 }}
        transparent={!isLivePage}
      >
        <Match path="/live/*">
        {({ match }) => (
          !match && (<Announcement>
            If you missed the live stream, rewatch it here: <AnnouncementButton onClick={() => navigate("/live")}>Salimbay 2021</AnnouncementButton>
          </Announcement>)
        )}
        </Match>
        <MarginContainer>
          <HeaderContainer>
            <MenuContainer onClick={() => setSidebarOpen(true)}>
              <Menu color="white" size={35} />
            </MenuContainer>
            <LogoContainer>
              <BoxImage src={UpManilaLogo} alt="UP Manila Logo" />
              <BoxImage src={UpcmManilaLogo} alt="UPCM Manila Hospital Logo" />
              <WiderBoxImage
                src={PghLogo}
                alt="Philippine General Hospital Logo"
              />
              <BoxImage src={UpcmClassLogo} alt="UPCM Class 2021 Logo" />
            </LogoContainer>
            <NavigationContainer>
              <Match path="/">
                {({ match }) => (<ResetLink to="/"><NavItem selected={match}>Home</NavItem></ResetLink>)}
              </Match>
              <Match path="/through-the-years/*">
                {({ match }) => (<ResetLink to="/through-the-years"><NavItem selected={match}>Through the Years</NavItem></ResetLink>)}
              </Match>
              <Match path="/programs/*">
                {({ match }) => (<ResetLink to="/programs"><NavItem selected={match}>Programs</NavItem></ResetLink>)}
              </Match>
              <Match path="/class2021/*">
                {({ match }) => (<ResetLink to="/class2021"><NavItem selected={match}>Class 2021</NavItem></ResetLink>)}
              </Match>
            </NavigationContainer>
          </HeaderContainer>
        </MarginContainer>
      </Container>
      { sideBarOpen && <Sidebar onClose={() => setSidebarOpen(false)} /> }
    </>
  )
}

export default Header
